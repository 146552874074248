
export default async function useDirectusTokenRefresh(to = '') {
  const router = useRouter();
  const { expires, token_expired, refreshTokens } = useDirectusToken();
  
  let path = '';
  if(to) {
    path = to
  } else {
    const route = useRoute();
    path = route.path;
  }
  
  // if(path === "/login" || path === "/register") return;

  const pathsToCheck = [
    '/account',
    '/account/wachtwoord-wijzigen',
    '/account/bestellingen',
    '/account/gegevens',
    '/account/adres',
  ];

  // if(pathsToCheck.includes(path)) {
    // console.log('Path requires token refresh');
    
    if(token_expired.value) {
      // console.log('Token expired, refreshing...');
      try {
        const result = await refreshTokens();

        // console.log('Token refreshed', result, token_expired.value);
        
        if(token_expired.value) {
          // console.log('Token could not be refreshed, redirecting to login');

          if(pathsToCheck.includes(path)) {
            router.push('/inloggen');
          }

        }
      } catch (error) {
        // console.log('Error refreshing token, redirecting to login', error);

        if(pathsToCheck.includes(path)) {
          router.push('/inloggen');
        }
      }
    } else {
      // console.log(`Token is valid, expires: ${new Date(expires.value).toLocaleDateString()} ${new Date(expires.value).toLocaleTimeString()} `);
    }
  // } else {
  //   console.log(`Path "${path}" does not require token refresh`);
  //   return;
  // }
}