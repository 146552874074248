import { default as adresxgLXDQB1J3Meta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/adres.vue?macro=true";
import { default as bestellinge5QayeUmoKMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/bestelling.vue?macro=true";
import { default as bestellingenlIfSkaxoEFMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/bestellingen.vue?macro=true";
import { default as gegevenszk1cLZf0QDMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/gegevens.vue?macro=true";
import { default as indextEz4ZAlDsxMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/index.vue?macro=true";
import { default as resetwachtwoordjhFHVglavOMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/resetwachtwoord.vue?macro=true";
import { default as wachtwoord_45wijzigen1KZ81O6jsjMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as activerenP0w3uJOtplMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/activeren.vue?macro=true";
import { default as afrekenenfSVG4xgc8QMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/afrekenen.vue?macro=true";
import { default as algemenevoorwaardenQyi0izO4j4Meta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/algemenevoorwaarden.vue?macro=true";
import { default as _91product_93c6EIq6sZZNMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/assortiment/[product].vue?macro=true";
import { default as index09OKzOr0DKMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/assortiment/index.vue?macro=true";
import { default as bestellingAfgerondw9vxBuzni1Meta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/bestellingAfgerond.vue?macro=true";
import { default as bestellingMisluktbXv3BUAH6UMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/bestellingMislukt.vue?macro=true";
import { default as bezorggebiedmLleX8BXpnMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/bezorggebied.vue?macro=true";
import { default as biologischyiB0CDriAJMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/biologisch.vue?macro=true";
import { default as boodschappenlijstMn9LrZ3JBkMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/boodschappenlijst.vue?macro=true";
import { default as _91categorie_93Qmc3ZY4turMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/categorie/[categorie].vue?macro=true";
import { default as contactL33Ic9BlavMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/contact.vue?macro=true";
import { default as index8cfpZsCyt9Meta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/index.vue?macro=true";
import { default as inloggenZTr6vy4C5HMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/inloggen.vue?macro=true";
import { default as inspiratie03GXoO60VwMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/inspiratie.vue?macro=true";
import { default as kistjesj3X5lsVnuFMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/kistjes.vue?macro=true";
import { default as overons1oMoyoqNyVMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/overons.vue?macro=true";
import { default as recensies2idWFLKSXDMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/recensies.vue?macro=true";
import { default as registrerenOSAM1mh4WZMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/registreren.vue?macro=true";
import { default as wat_45zeggen_45onze_45klantenxPUMIgSTA9Meta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/wat-zeggen-onze-klanten.vue?macro=true";
import { default as zoekenwjgd3oYesjMeta } from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/zoeken.vue?macro=true";
export default [
  {
    name: adresxgLXDQB1J3Meta?.name ?? "account-adres",
    path: adresxgLXDQB1J3Meta?.path ?? "/account/adres",
    meta: adresxgLXDQB1J3Meta || {},
    alias: adresxgLXDQB1J3Meta?.alias || [],
    redirect: adresxgLXDQB1J3Meta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/adres.vue").then(m => m.default || m)
  },
  {
    name: bestellinge5QayeUmoKMeta?.name ?? "account-bestelling",
    path: bestellinge5QayeUmoKMeta?.path ?? "/account/bestelling",
    meta: bestellinge5QayeUmoKMeta || {},
    alias: bestellinge5QayeUmoKMeta?.alias || [],
    redirect: bestellinge5QayeUmoKMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/bestelling.vue").then(m => m.default || m)
  },
  {
    name: bestellingenlIfSkaxoEFMeta?.name ?? "account-bestellingen",
    path: bestellingenlIfSkaxoEFMeta?.path ?? "/account/bestellingen",
    meta: bestellingenlIfSkaxoEFMeta || {},
    alias: bestellingenlIfSkaxoEFMeta?.alias || [],
    redirect: bestellingenlIfSkaxoEFMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/bestellingen.vue").then(m => m.default || m)
  },
  {
    name: gegevenszk1cLZf0QDMeta?.name ?? "account-gegevens",
    path: gegevenszk1cLZf0QDMeta?.path ?? "/account/gegevens",
    meta: gegevenszk1cLZf0QDMeta || {},
    alias: gegevenszk1cLZf0QDMeta?.alias || [],
    redirect: gegevenszk1cLZf0QDMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/gegevens.vue").then(m => m.default || m)
  },
  {
    name: indextEz4ZAlDsxMeta?.name ?? "account",
    path: indextEz4ZAlDsxMeta?.path ?? "/account",
    meta: indextEz4ZAlDsxMeta || {},
    alias: indextEz4ZAlDsxMeta?.alias || [],
    redirect: indextEz4ZAlDsxMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: resetwachtwoordjhFHVglavOMeta?.name ?? "account-resetwachtwoord",
    path: resetwachtwoordjhFHVglavOMeta?.path ?? "/account/resetwachtwoord",
    meta: resetwachtwoordjhFHVglavOMeta || {},
    alias: resetwachtwoordjhFHVglavOMeta?.alias || [],
    redirect: resetwachtwoordjhFHVglavOMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/resetwachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45wijzigen1KZ81O6jsjMeta?.name ?? "account-wachtwoord-wijzigen",
    path: wachtwoord_45wijzigen1KZ81O6jsjMeta?.path ?? "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigen1KZ81O6jsjMeta || {},
    alias: wachtwoord_45wijzigen1KZ81O6jsjMeta?.alias || [],
    redirect: wachtwoord_45wijzigen1KZ81O6jsjMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: activerenP0w3uJOtplMeta?.name ?? "activeren",
    path: activerenP0w3uJOtplMeta?.path ?? "/activeren",
    meta: activerenP0w3uJOtplMeta || {},
    alias: activerenP0w3uJOtplMeta?.alias || [],
    redirect: activerenP0w3uJOtplMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/activeren.vue").then(m => m.default || m)
  },
  {
    name: afrekenenfSVG4xgc8QMeta?.name ?? "afrekenen",
    path: afrekenenfSVG4xgc8QMeta?.path ?? "/afrekenen",
    meta: afrekenenfSVG4xgc8QMeta || {},
    alias: afrekenenfSVG4xgc8QMeta?.alias || [],
    redirect: afrekenenfSVG4xgc8QMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/afrekenen.vue").then(m => m.default || m)
  },
  {
    name: algemenevoorwaardenQyi0izO4j4Meta?.name ?? "algemenevoorwaarden",
    path: algemenevoorwaardenQyi0izO4j4Meta?.path ?? "/algemenevoorwaarden",
    meta: algemenevoorwaardenQyi0izO4j4Meta || {},
    alias: algemenevoorwaardenQyi0izO4j4Meta?.alias || [],
    redirect: algemenevoorwaardenQyi0izO4j4Meta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/algemenevoorwaarden.vue").then(m => m.default || m)
  },
  {
    name: _91product_93c6EIq6sZZNMeta?.name ?? "assortiment-product",
    path: _91product_93c6EIq6sZZNMeta?.path ?? "/assortiment/:product()",
    meta: _91product_93c6EIq6sZZNMeta || {},
    alias: _91product_93c6EIq6sZZNMeta?.alias || [],
    redirect: _91product_93c6EIq6sZZNMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/assortiment/[product].vue").then(m => m.default || m)
  },
  {
    name: index09OKzOr0DKMeta?.name ?? "assortiment",
    path: index09OKzOr0DKMeta?.path ?? "/assortiment",
    meta: index09OKzOr0DKMeta || {},
    alias: index09OKzOr0DKMeta?.alias || [],
    redirect: index09OKzOr0DKMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/assortiment/index.vue").then(m => m.default || m)
  },
  {
    name: bestellingAfgerondw9vxBuzni1Meta?.name ?? "bestellingAfgerond",
    path: bestellingAfgerondw9vxBuzni1Meta?.path ?? "/bestellingAfgerond",
    meta: bestellingAfgerondw9vxBuzni1Meta || {},
    alias: bestellingAfgerondw9vxBuzni1Meta?.alias || [],
    redirect: bestellingAfgerondw9vxBuzni1Meta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/bestellingAfgerond.vue").then(m => m.default || m)
  },
  {
    name: bestellingMisluktbXv3BUAH6UMeta?.name ?? "bestellingMislukt",
    path: bestellingMisluktbXv3BUAH6UMeta?.path ?? "/bestellingMislukt",
    meta: bestellingMisluktbXv3BUAH6UMeta || {},
    alias: bestellingMisluktbXv3BUAH6UMeta?.alias || [],
    redirect: bestellingMisluktbXv3BUAH6UMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/bestellingMislukt.vue").then(m => m.default || m)
  },
  {
    name: bezorggebiedmLleX8BXpnMeta?.name ?? "bezorggebied",
    path: bezorggebiedmLleX8BXpnMeta?.path ?? "/bezorggebied",
    meta: bezorggebiedmLleX8BXpnMeta || {},
    alias: bezorggebiedmLleX8BXpnMeta?.alias || [],
    redirect: bezorggebiedmLleX8BXpnMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/bezorggebied.vue").then(m => m.default || m)
  },
  {
    name: biologischyiB0CDriAJMeta?.name ?? "biologisch",
    path: biologischyiB0CDriAJMeta?.path ?? "/biologisch",
    meta: biologischyiB0CDriAJMeta || {},
    alias: biologischyiB0CDriAJMeta?.alias || [],
    redirect: biologischyiB0CDriAJMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/biologisch.vue").then(m => m.default || m)
  },
  {
    name: boodschappenlijstMn9LrZ3JBkMeta?.name ?? "boodschappenlijst",
    path: boodschappenlijstMn9LrZ3JBkMeta?.path ?? "/boodschappenlijst",
    meta: boodschappenlijstMn9LrZ3JBkMeta || {},
    alias: boodschappenlijstMn9LrZ3JBkMeta?.alias || [],
    redirect: boodschappenlijstMn9LrZ3JBkMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/boodschappenlijst.vue").then(m => m.default || m)
  },
  {
    name: _91categorie_93Qmc3ZY4turMeta?.name ?? "categorie-categorie",
    path: _91categorie_93Qmc3ZY4turMeta?.path ?? "/categorie/:categorie()",
    meta: _91categorie_93Qmc3ZY4turMeta || {},
    alias: _91categorie_93Qmc3ZY4turMeta?.alias || [],
    redirect: _91categorie_93Qmc3ZY4turMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/categorie/[categorie].vue").then(m => m.default || m)
  },
  {
    name: contactL33Ic9BlavMeta?.name ?? "contact",
    path: contactL33Ic9BlavMeta?.path ?? "/contact",
    meta: contactL33Ic9BlavMeta || {},
    alias: contactL33Ic9BlavMeta?.alias || [],
    redirect: contactL33Ic9BlavMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index8cfpZsCyt9Meta?.name ?? "index",
    path: index8cfpZsCyt9Meta?.path ?? "/",
    meta: index8cfpZsCyt9Meta || {},
    alias: index8cfpZsCyt9Meta?.alias || [],
    redirect: index8cfpZsCyt9Meta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inloggenZTr6vy4C5HMeta?.name ?? "inloggen",
    path: inloggenZTr6vy4C5HMeta?.path ?? "/inloggen",
    meta: inloggenZTr6vy4C5HMeta || {},
    alias: inloggenZTr6vy4C5HMeta?.alias || [],
    redirect: inloggenZTr6vy4C5HMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: inspiratie03GXoO60VwMeta?.name ?? "inspiratie",
    path: inspiratie03GXoO60VwMeta?.path ?? "/inspiratie",
    meta: inspiratie03GXoO60VwMeta || {},
    alias: inspiratie03GXoO60VwMeta?.alias || [],
    redirect: inspiratie03GXoO60VwMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/inspiratie.vue").then(m => m.default || m)
  },
  {
    name: kistjesj3X5lsVnuFMeta?.name ?? "kistjes",
    path: kistjesj3X5lsVnuFMeta?.path ?? "/kistjes",
    meta: kistjesj3X5lsVnuFMeta || {},
    alias: kistjesj3X5lsVnuFMeta?.alias || [],
    redirect: kistjesj3X5lsVnuFMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/kistjes.vue").then(m => m.default || m)
  },
  {
    name: overons1oMoyoqNyVMeta?.name ?? "overons",
    path: overons1oMoyoqNyVMeta?.path ?? "/overons",
    meta: overons1oMoyoqNyVMeta || {},
    alias: overons1oMoyoqNyVMeta?.alias || [],
    redirect: overons1oMoyoqNyVMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/overons.vue").then(m => m.default || m)
  },
  {
    name: recensies2idWFLKSXDMeta?.name ?? "recensies",
    path: recensies2idWFLKSXDMeta?.path ?? "/recensies",
    meta: recensies2idWFLKSXDMeta || {},
    alias: recensies2idWFLKSXDMeta?.alias || [],
    redirect: recensies2idWFLKSXDMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/recensies.vue").then(m => m.default || m)
  },
  {
    name: registrerenOSAM1mh4WZMeta?.name ?? "registreren",
    path: registrerenOSAM1mh4WZMeta?.path ?? "/registreren",
    meta: registrerenOSAM1mh4WZMeta || {},
    alias: registrerenOSAM1mh4WZMeta?.alias || [],
    redirect: registrerenOSAM1mh4WZMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/registreren.vue").then(m => m.default || m)
  },
  {
    name: wat_45zeggen_45onze_45klantenxPUMIgSTA9Meta?.name ?? "wat-zeggen-onze-klanten",
    path: wat_45zeggen_45onze_45klantenxPUMIgSTA9Meta?.path ?? "/wat-zeggen-onze-klanten",
    meta: wat_45zeggen_45onze_45klantenxPUMIgSTA9Meta || {},
    alias: wat_45zeggen_45onze_45klantenxPUMIgSTA9Meta?.alias || [],
    redirect: wat_45zeggen_45onze_45klantenxPUMIgSTA9Meta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/wat-zeggen-onze-klanten.vue").then(m => m.default || m)
  },
  {
    name: zoekenwjgd3oYesjMeta?.name ?? "zoeken",
    path: zoekenwjgd3oYesjMeta?.path ?? "/zoeken",
    meta: zoekenwjgd3oYesjMeta || {},
    alias: zoekenwjgd3oYesjMeta?.alias || [],
    redirect: zoekenwjgd3oYesjMeta?.redirect,
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/pages/zoeken.vue").then(m => m.default || m)
  }
]