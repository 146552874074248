import validate from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/middleware/auth.global.ts";
import manifest_45route_45rule from "/home/cleavr/versvandevelde.nl/releases/20240827062513912/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "set-dynamic-routes": () => import("/home/cleavr/versvandevelde.nl/releases/20240827062513912/middleware/setDynamicRoutes.js")
}