export default defineNuxtRouteMiddleware(async (to, _from) => {


    await useDirectusTokenRefresh(to.path);

    if (to.path === "/account" || to.path === "/account/wachtwoord-wijzigen" || to.path === "/account/bestellingen" || to.path === "/account/gegevens" || to.path === "/account/adres" || to.path === "/account/bestelling/") {
        const user = useDirectusUser();
        if (user.value) {
            if (user.value.email.includes('example.com') && user.value.email.includes('temp_')) {
                return navigateTo('/');
            }
        }
    }
});