<template>
  <NuxtLayout>
    <HerbruikbaarFullunderline class="hidden sm:block"/>
    <ErrorpageGrote404/>
    <div class="mx-5 text-center md:mx-0 pb-10">
      <h1 class="mt-5 text-xl sm:text-2xl md:text-3xl text-primary sm:mt-20">
        We kunnen deze pagina niet vinden
      </h1>
      <h2 class="mt-3 text-base sm:text-md">
        Bekijk de website-Url en probeer het opnieuw,<br/>of vind wat je zoekt op onze startpagina.
      </h2>
      <button
          class="px-6 py-2 mt-5 text-sm border-2 rounded-full border-primary-500 hover:bg-primary-500 auto-transit hover:text-white"
          @click="$router.go(-1)">Teruggaan
      </button>
    </div>
  </NuxtLayout>
</template>

<!--<template>-->
<!--  <div>-->
<!--    <NuxtLayout class="flex items-center">-->
<!--        <div class="container mx-auto px-5 sm:px-6 md:px-7 lg:px-10 xl:px-12 my-10">-->
<!--          <div class="flex flex-col items-center">-->
<!--&lt;!&ndash;                  <img src="/images/404.svg" alt="404" class="w-2/3 md:w-1/4 my-10">&ndash;&gt;-->
<!--            <p class="text-2xl font-bold text-gray-700 mt-4">Deze pagina bestaat niet</p>-->
<!--            <p class="text-gray-700 mt-4">De pagina die u probeert te bezoeken bestaat niet. Klik op de knop hieronder-->
<!--              om-->
<!--              terug te gaan naar de homepage.</p>-->
<!--            <div class="mt-4">-->
<!--              <NuxtLink to="/" class="btn btn-primary">Terug naar de homepage</NuxtLink>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--    </NuxtLayout>-->
<!--  </div>-->
<!--</template>-->

<!--<template>-->
<!--  <div>-->
<!--    <NuxtLayout class="flex items-center">-->
<!--      <div class="container mx-auto px-5 sm:px-6 md:px-7 lg:px-10 xl:px-12 my-10">-->
<!--        <div class="flex flex-col items-center">-->
<!--          <h1>{{ error.message }}</h1>-->
<!--          <br>-->
<!--          <NuxtLink to="/">-->
<!--            Terug naar de homepagina-->
<!--          </NuxtLink>-->
<!--        </div>-->
<!--      </div>-->
<!--    </NuxtLayout>-->
<!--  </div>-->
<!--</template>-->

<!--<script setup lang="ts">-->
<!--defineProps({-->
<!--  error: Object-->
<!--})-->

<!--const handleError = () => clearError({ redirect: '/' })-->
<!--</script>-->
<script setup lang="ts">
</script>